import radioStyles from "../components/stations-list/stations-list.module.scss";
import podcastStyles from "../components/podcats-list/podcast-list.module.scss";
import categoryStyles from "../components/category-list/category-list.module.scss";

const RADIOSTATION_BOX_WIDTH = Number(radioStyles.radioStationOuterWidth.replace('rem', '')) * 10;
const RADIOSTATION_BOX_WIDTH_MOBILE = Number(radioStyles.radioStationOuterWidthMobile.replace('rem', '')) * 10;
const PODCAST_BOX_WIDTH = Number(podcastStyles.podcastOuterWidth.replace('rem', '')) * 10;
const PODCAST_BOX_WIDTH_MOBILE = Number(podcastStyles.podcastOuterWidthMobile.replace('rem', '')) * 10;
const CATEGORY_BOX_WIDTH = Number(categoryStyles.categoryOuterWidth.replace('rem', '')) * 10;;


const getPadding = () => {
  if (window.innerWidth < 421) {
    return 0;
  }
  if (window.innerWidth < 1025) {
    return 32;
  }
  return 50;
}

const getRadioWidth = (isMobile: boolean) => {
  return isMobile ? RADIOSTATION_BOX_WIDTH_MOBILE : RADIOSTATION_BOX_WIDTH;
}
const getPodcastWidth = (isMobile: boolean) => {
  return isMobile ? PODCAST_BOX_WIDTH_MOBILE : PODCAST_BOX_WIDTH;
}

export const calculateRadioScroll = (scrollLeft: number, substract = false, isMobile = false) => {
  const windowWidth = window.innerWidth - getPadding();
  const startWidth = substract ? scrollLeft - windowWidth : scrollLeft + windowWidth;
  return startWidth - (((startWidth) / getRadioWidth(isMobile)) - Math.floor((startWidth) / getRadioWidth(isMobile))) * getRadioWidth(isMobile);
};

export const calculatePodcastScroll = (scrollLeft: number, substract = false, isMobile = false) => {
  const windowWidth = window.innerWidth - getPadding();
  const startWidth = substract ? scrollLeft - windowWidth : scrollLeft + windowWidth;
  return startWidth - (((startWidth) / getPodcastWidth(isMobile)) - Math.floor((startWidth) / getPodcastWidth(isMobile))) * getPodcastWidth(isMobile);
};

export const calculateCategoryScroll = (scrollLeft: number, substract = false) => {
  const windowWidth = window.innerWidth - getPadding();
  const startWidth = substract ? scrollLeft - windowWidth : scrollLeft + windowWidth;
  return startWidth - (((startWidth) / CATEGORY_BOX_WIDTH) - Math.floor((startWidth) / CATEGORY_BOX_WIDTH)) * CATEGORY_BOX_WIDTH;
};