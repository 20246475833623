import { playerActions } from "app/store/slices/player";
import { useAppDispatch } from "app/store/store";
import clsx from "clsx";
import { Category } from "services-hooks/types";
import styles from "./stations-list.module.scss";
import { radioActions } from "../../store/slices/radio";
import { splitToChunks } from "app/utils/array";
import ArrowRight from "../icons/ArrowRight";
import { useRef, useState } from "react";
import { v4 } from "uuid";
import { usePlayerContext } from "app/context/player.context";
import { StationsRow } from "./stations-row.component";
import { useGoToRadio } from "app/hooks/navigation.hook";
import { calculateRadioScroll } from "app/utils/scroll";
import { useIsMobileResolution } from "app/utils/is-mobile-resolution";

export interface StationsListProps {
  items: Category[];
  label?: string;
  loading: boolean;
  showDetailsOnClick?: boolean;
  wrapped?: boolean;
  rows?: number;
  className?: string;
  showStationNames?: boolean;
  fetchNextPage?: VoidFunction;
}

export const StationsList: React.FC<StationsListProps> = ({
  items,
  label,
  loading,
  showDetailsOnClick,
  wrapped,
  className,
  rows = 1,
  showStationNames = false,
  fetchNextPage = () => {},
}) => {
  const goToRadio = useGoToRadio();
  const [scrollSyncId] = useState(() => v4());
  const dispatch = useAppDispatch();
  const { setPlayerEnabled, setFullscreenPlayerInitialyOpen } = usePlayerContext();
  const radioListRef = useRef<HTMLDivElement>(null);
  const itemRows = splitToChunks(items, items.length / rows > 7 ? rows : 1);
  const handleOnClick = (radioStation: Category, stationIndex: number) => {
    if (showDetailsOnClick) {
      goToRadio(`/${radioStation.permalink}`);
      dispatch(
        radioActions.selectRadio({
          section: items,
          activeIndex: items.indexOf(radioStation),
        })
      );
    } else {
      setFullscreenPlayerInitialyOpen(true);
      setPlayerEnabled(true);
      dispatch(
        playerActions.selectTrack({
          category: radioStation,
          podcast: null,
        })
      );
      dispatch(
        playerActions.setRadioQueue({
          items: items,
          activeIndex: items.indexOf(radioStation),
        })
      );
    }
  };

  const isMobile = useIsMobileResolution();

  const onForward = () => {
    radioListRef.current?.scrollTo({
      left: calculateRadioScroll(radioListRef.current.scrollLeft, false, isMobile),
      behavior: "smooth",
    });
  };

  const onBackward = () => {
    radioListRef.current?.scrollTo({
      left: calculateRadioScroll(radioListRef.current.scrollLeft, true, isMobile),
      behavior: "smooth",
    });
  };

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.header}>
        {<h2>{label}</h2>}
        {!wrapped && (
          <div className={styles.buttons}>
            <button
              className={clsx(styles.arrow, styles.arrow_back)}
              onClick={() => onBackward()}
            >
              <ArrowRight />
            </button>
            <button className={styles.arrow} onClick={() => onForward()}>
              <ArrowRight />
            </button>
          </div>
        )}
      </div>

      <div className={styles.radio_list} ref={radioListRef}>
        {loading ? (
          <div className={styles.row}>
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
            <div
              className={clsx(styles.radio_station, styles.placeholder_item)}
            />
          </div>
        ) : (
          itemRows.map((row, rowIndex) => (
            <StationsRow
              key={`radio-stations-row-${rowIndex}`}
              items={row}
              scrollSyncId={scrollSyncId}
              handleOnClick={handleOnClick}
              showStationNames={showStationNames}
              wrapped={wrapped}
              fetchNextPage={fetchNextPage}
              triggerFetchNextPage={rowIndex === 0}
            />
          ))
        )}
      </div>
    </div>
  );
};
